/* eslint-disable */
import instance from "@/services/instance.js";

const GetAllOrg = payload => {
  return instance.instanceToken
    .get("/review/org/all")
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

const GetAllVenue = payload => {
  return instance.instanceToken
    .get("/review/venue/all")
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

export default {
  getAllOrg: GetAllOrg,
  getAllVenue: GetAllVenue
};
