<template>
  <v-container id="subscPlane-page" fluid tag="section">
    <v-row class="mx-0" align="center" justify="center">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <v-tabs
              v-model="reviewTabs"
              background-color="transparent"
              slider-color="white"
            >
              <span
                class="display-2 font-weight-light mx-3"
                style="align-self: center"
                >Reviews:</span
              >
              <v-tab class="mr-3">
                <font-awesome-icon :icon="['fas', 'user-tie']" class="mr-2" />
                Organiser
              </v-tab>
              <v-tab class="mr-3">
                <font-awesome-icon :icon="['fas', 'building']" class="mr-2" />
                Venue
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="reviewTabs" class="transparent">
            <v-tab-item>
              <v-card-text>
                <v-data-table
                  :headers="headersOrganiser"
                  :items="itemsOrg"
                  :loading="isLoading"
                >
                  <template v-slot:item.reviewState="{ item }">
                    <span
                      class="text-center subtitle-1 secondary--text"
                      v-if="item.reviewState == 0"
                      >Initialized</span
                    >
                    <span
                      class="text-center subtitle-1 success--text"
                      v-if="item.reviewState == 1"
                      >Accepted</span
                    >
                    <span
                      class="text-center subtitle-1 error--text"
                      v-if="item.reviewState == 2"
                      >Rejected</span
                    >
                  </template>
                  <template v-slot:item.status="{ item }">
                    <OrganiserFeedback :item="item" />
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text>
                <v-data-table :headers="headersVenue" :items="itemsVenue">
                  <template v-slot:item.reviewState="{ item }">
                    <span
                      class="text-center subtitle-1 secondary--text"
                      v-if="item.reviewState == 0"
                      >Initialized</span
                    >
                    <span
                      class="text-center subtitle-1 success--text"
                      v-if="item.reviewState == 1"
                      >Accepted</span
                    >
                    <span
                      class="text-center subtitle-1 error--text"
                      v-if="item.reviewState == 2"
                      >Rejected</span
                    >
                  </template>
                  <template v-slot:item.status="{ item }">
                    <VenueFeedback :item="item" />
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import reviewsApicalls from "../../../services/reviews.service";
import OrganiserFeedback from "./OrganiserFeedback";
import VenueFeedback from "./VenueFeedback";

export default {
  name: "StripeSubscriptionPlan",
  components: {
    OrganiserFeedback,
    VenueFeedback
  },
  data() {
    return {
      reviewTabs: 0,
      isLoading: false,
      name: null,
      amount: null,
      intType: null,
      intervaltypes: ["Day", "Week", "Month", "Year"],
      errorName: null,
      errorAmount: null,
      errorIntType: null,
      staticErrorMessages: {
        name: {
          isEmpty: "Name cannot be empty"
        },
        amount: {
          isEmpty: "Amount cannot be empty"
        },
        intType: {
          isEmpty: "Please select a suitable Interval type"
        }
      },
      headersOrganiser: [
        {
          sortable: true,
          text: "Title",
          value: "title",
          class: "primary--text title"
        },
        {
          sortable: true,
          text: "Name",
          value: "organiser.name",
          class: "primary--text title"
        },
        {
          sortable: true,
          text: "State",
          value: "reviewState",
          class: "primary--text title"
        },
        {
          sortable: false,
          text: "More",
          value: "status",
          class: "primary--text title"
        }
      ],
      headersVenue: [
        {
          sortable: true,
          text: "Title",
          value: "title",
          class: "primary--text title"
        },
        {
          sortable: true,
          text: "Name",
          value: "venue.name",
          class: "primary--text title"
        },
        {
          sortable: true,
          text: "State",
          value: "reviewState",
          class: "primary--text title"
        },
        {
          sortable: false,
          text: "More",
          value: "status",
          class: "primary--text title"
        }
      ],
      itemsOrg: [],
      itemsVenue: []
    };
  },
  mounted() {
    this.isLoading = true;
    reviewsApicalls.getAllOrg().then(res => {
      // console.log(res.data.content.data);
      this.itemsOrg = res.data.content.data;
      this.isLoading = false;
    });
    reviewsApicalls.getAllVenue().then(res => {
      // console.log(res.data.content.data);
      this.itemsVenue = res.data.content.data;
      this.isLoading = false;
    });
  },
  methods: {}
};
</script>
<style>
#register {
  background-image: linear-gradient(
      to left,
      rgba(53, 167, 255, 0.3),
      rgba(53, 167, 255, 0.3)
    ),
    url("https://source.unsplash.com/w1g2o4J_4Dg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.register-form-card {
  position: relative;
  width: 600px;
}
.register-form-row {
  /* position: absolute; */
  top: 0;
  min-width: 100vw;
  /* the 64px is to offset the top app bar */
  height: calc(100vh - 64px);
  min-height: 300px;
}
.register-image-credits {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
}
.register-image-credits a {
  color: white;
}
.register-image-credits a:hover {
  color: gray;
}
/* Medium devices (large tablet to laptop, less than 960px) */
@media (max-width: 960px) {
  .register-form-card {
    width: 80vw;
    min-width: 200px;
  }
  /* the 56px is to offset the top app bar on smaller devices */
  .register-cover-image {
    height: calc(100vh - 56px);
  }
  /* the 56px is to offset the top app bar on smaller devices */
  .register-form-row {
    height: calc(100vh - 56px);
  }
}
</style>
