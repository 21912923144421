<template>
  <v-dialog v-model="feedbackDialog" scrollable max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn icon color="grey lighten-1" v-on="on">
        <font-awesome-icon :icon="['fas', 'chevron-right']" />
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="pb-3 px-2 px-sm-6">
        <v-row>
          <v-col
            cols="8"
            lg="10"
            class="d-flex flex-column justify-center align-start py-0"
          >
            <h2 class="headline text-left text-break">Venue Feedback</h2>
          </v-col>
          <v-col
            cols="4"
            lg="2"
            class="d-flex flex-column justify-center align-end py-0"
          >
            <v-btn icon color="secondary" @click="closeDialog">
              <v-icon class="dialog-close-icon">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container class="px-0">
          <v-row class="d-flex flex-row-reverse align-start justify-center">
            <v-col cols="12" class="py-0">
              <v-row class="mx-0">
                <v-col cols="8">
                  <span class="text-left subtitle-1">Event Name:</span>
                </v-col>
                <v-col cols="4">
                  <span class="text-left subtitle-2">{{ item.title }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              class="d-flex flex-column align-center justify-center my-3 px-0 px-md-3"
              v-for="(review, index) in item.reviews"
              :key="index"
            >
              <h5 class="title text-center secondary--text">
                {{ review.question }}
              </h5>
              <v-col cols="12" sm="10">
                <v-rating
                  class="rating-element d-flex flex-row align-center justify-space-between mt-4"
                  v-model="review.rating"
                  background-color="orange lighten-3"
                  large
                  length="5"
                  hover
                >
                  <template v-slot:item="props">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="orange lighten-3"
                          large
                          v-on="on"
                          class="rating-icon"
                          >{{
                            props.isFilled || props.isHovered
                              ? "mdi-star"
                              : "mdi-star-outline"
                          }}</v-icon
                        >
                      </template>
                      <span>{{ genRating(props.index) }}</span>
                    </v-tooltip>
                  </template>
                </v-rating>
                <v-row
                  class="d-flex flex-row align-center justify-space-between mx-0 mt-3 px-2 w-100"
                >
                  <span
                    class="caption text-left platinum--text text--darken-3 font-weight-bold"
                    >{{ review.ratingStartText }}</span
                  >
                  <span
                    class="caption text-right platinum--text text--darken-3 font-weight-bold"
                    >{{ review.ratingEndText }}</span
                  >
                </v-row>
              </v-col>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Feedback",
  props: ["item"],
  data() {
    return {
      isApiLoad: false,
      orgId: null,
      rfpId: null,
      feedbackDialog: false,
      ratingList: [
        "Totally Dissatisfied",
        "Dissatisfied",
        "Neutral",
        "Satisfied",
        "Totally Satisfied"
      ],
      reviewList: [
        {
          questionId: 1,
          question: "Was the Conference Organizer clear on their wishes?",
          rating: 0,
          ratingStartText: "Totally Dissatisfied",
          ratingEndText: "Totally Satisfied"
        }
      ]
    };
  },
  computed: {
    manualList() {
      let tmp = [];
      for (let i = 0; i < this.reviewList.length; i++) {
        tmp.push({
          questionId: this.reviewList[i].questionId,
          question: this.reviewList[i].question,
          rating: this.reviewList[i].rating
        });
      }

      return tmp;
    }
  },
  methods: {
    genRating(i) {
      return this.ratingList[i];
    },
    closeDialog() {
      this.feedbackDialog = false;
    }
  }
};
</script>

<style scoped>
.dashboard-card {
  cursor: pointer;
}
.dashboard-card .dashboard-card-icon {
  color: var(--v-primary-base);
  opacity: 0.7;
  transition: color 0.3s ease-in-out;
}
.dashboard-card .dashboard-card-icon:not(.on-hover) {
  color: var(--v-platinum-base);
  opacity: 1;
}
</style>
<style>
.v-rating .v-icon {
  padding: 4px !important;
  /* css fix (temp) */
  -webkit-appearance: none;
}
</style>
